import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
// import NelsonModel from "./NelsonModel";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
// import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    // const gaEventTracker = useAnalyticsEventTracker("Intro Page");

    return (
      <div id="intro">
        {/* <div id="nelson-model">
          <NelsonModel></NelsonModel>
        </div> */}
        <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"Hi, "}
            <span className="intro-name">{"Nelson"}</span>
            {" here."}
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">
            I code and play video games sometimes.
          </div>
          <div className="intro-desc">
            I'm an aspiring software engineer && cyber security expert based in
            Kamloops. I have profound interests in cyber security, avionics,
            frontend development, and everything in between.
          </div>
          <a
            href="mailto:nelsonmuthiga@gmail.com"
            className="intro-contact"
            // onClick={() => gaEventTracker("email")}
          >
            <EmailRoundedIcon></EmailRoundedIcon>
            {"Say hello"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;
