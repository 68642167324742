import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const projects = {
      "React.js Covid-19 Tracker": {
        desc: "This is a web app built using React that gets data from a public Covid-19 API, and displays it.",
        techStack: "Javascript ES6+, Node.js, CSS",
        link: "https://github.com/nelsonkarugu/react-covid-tracker",
        open: "https://covid-tracker-22b4a.web.app/",
      },
      "Signal messenger clone.": {
        desc: "A barebones version of the Signal messaging app built using React.js utilizing firebase for the backend",
        techStack: "Javascript ES6+, React.js, Firebase",
        link: "https://github.com/nelsonkarugu/signal-clone",
      },
      "Discord Bot": {
        desc: "A customizable discord bot built using javascript",
        techStack: "Javascript ES6+",
        link: "https://github.com/nelsonkarugu/security",
      },
    };

    // const gaEventTracker = useAnalyticsEventTracker("Projects Page");

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ software-creations</span>
        </div>

        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card" key={key}>
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <span className="external-links">
                      <a
                        className="github-icon"
                        href={projects[key]["link"]}
                        // onClick={() => gaEventTracker("github")}
                      >
                        <GitHubIcon
                          style={{
                            fontSize: 20,
                            color: "var(--lightest-slate)",
                          }}
                        ></GitHubIcon>
                      </a>
                      {projects[key]["open"] && (
                        <a
                          className="open-icon"
                          href={projects[key]["open"]}
                          rel="noopener noreferrer"
                          target="_blank"
                          // onClick={() => gaEventTracker("external redirect")}
                        >
                          <OpenInBrowserIcon
                            style={{
                              fontSize: 25,
                              color: "var(--lightest-slate)",
                            }}
                          ></OpenInBrowserIcon>
                        </a>
                      )}
                    </span>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
