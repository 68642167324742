import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";
// import photo from "../components/assets/Image20.JPG";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    // const gaEventTracker = useAnalyticsEventTracker("About Page");

    const one = (
      <p>
        I am currently studying <b>Computer Science</b> at{" "}
        <b> Thompson Rivers University </b>
      </p>
    );
    const two = (
      <p>
        I'm into <b>cyber security</b>, <b>software designing</b> and{" "}
        <b>front and backend development</b>. In my free time, I binge watch
        shows and play video games.
      </p>
    );
    const three = (
      <p>
        <b>Want to chat?</b> Shoot me a message at{" "}
        <a
          href="mailto:nelsonmuthiga@gmail.com"
          // onClick={() => gaEventTracker("email")}
        >
          nelsonmuthiga@gmail.com
        </a>{" "}
        and let's talk.
      </p>
    );
    const desc_items = [one, two, three];

    const tech_stack = [
      "Javascript ES6+",
      "Typescript",
      "Python",
      "React.js",
      "Java",
      "Node.js",
      "HTML & CSS",
			"React Native"
    ];

    // eslint-disable-next-line no-unused-vars
    const tech_items = tech_stack.map((stack) => <li key={stack}>{stack}</li>);

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {desc_items}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li key={tech_item}>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
            </div>
            {/* <div className="about-image">
              <img
                src={
                }
                alt="me"
              />
            </div> */}
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
